var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.observation_table)?[_c('b-tabs',{model:{value:(_vm.tab_index),callback:function ($$v) {_vm.tab_index=$$v},expression:"tab_index"}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_c('b-icon',{attrs:{"icon":"card-text"}}),_vm._v(" POR PREGUNTA ")],1)]},proxy:true}],null,false,3600113998)},[_c('b-table-simple',{attrs:{"bordered":"","small":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class",attrs:{"colspan":4}},[_c('span',[_vm._v(" PREGUNTAS ")]),(
                    _vm.allows_crud &&
                    _vm.test &&
                    !_vm.test.question_creation_is_locked &&
                    (!_vm.evaluationSituation ||
                      (_vm.evaluationSituation &&
                        ![
                          'duoc_ejecucion_practica_test',
                          'duoc_entrega_de_encargo_test',
                          'duoc_presentacion_test',
                        ].includes(_vm.evaluationSituation.internal_use_id) &&
                        _vm.evaluationSituation.enable_questions &&
                        (_vm.evaluationSituation.enable_redaction_questions ||
                          _vm.evaluationSituation.enable_simple_selection_questions ||
                          _vm.evaluationSituation.enable_multiple_selection_questions ||
                          _vm.evaluationSituation.enable_matching_questions)))
                  )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                    `Agregar Pregunta`
                  ),expression:"\n                    `Agregar Pregunta`\n                  ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"m-0 mb-1 p-0 text-white",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-created-question-nw-st-${_vm.test.id}`)}}}):_vm._e()],1),_c('b-th',{staticClass:"th-class",attrs:{"colspan":2}},[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.indicador_de_logro", true, "Indicadores de Logros" ).toUpperCase()))])],1),_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v("N° ORDEN")]),_c('b-th',{staticClass:"th-class"},[_vm._v("TIPO DE PREGUNTA")]),_c('b-th',{staticClass:"th-class"},[_vm._v("SUB-TIPO DE PREGUNTA")]),_c('b-th',{staticClass:"th-class"},[_vm._v("PUNTAJE")]),_c('b-th',{staticClass:"th-class"},[_vm._v("N°")]),_c('b-th',{staticClass:"th-class"},[_vm._v("ENUNCIADO")])],1)],1),_c('b-tbody',[_vm._l((_vm.questionList),function(question){return _c('b-tr',{key:question.id + '-' + question.question_type + '-' + _vm.test.id},[_c('b-td',{staticClass:"text-right"},[(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar Pregunta`
                  ),expression:"\n                    `Editar Pregunta`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-updated-question-nw-st-1-${question.test}-${question.question_type}-${question.id}`
                    )}}}):_vm._e(),_c('b-modal',{attrs:{"id":`modal-updated-question-nw-st-1-${question.test}-${question.question_type}-${question.id}`,"title":`Editar ${_vm.$getVisibleNames(
                    'evaluations2.redactionquestion',
                    false,
                    'Pregunta'
                  )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                        `modal-updated-question-nw-st-1-${question.test}-${question.question_type}-${question.id}`
                      )}}})],1)],1),_c('b-td',[_vm._v(_vm._s(_vm.getQuestionType(question.question_type)))]),_c('b-td',[_vm._v(_vm._s(_vm.getQuestionSubType(question)))]),_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(question.max_score))]),_c('b-td',{staticClass:"text-center"},[(question.evaluation_criteria_micro_obj != null)?[_vm._v(" "+_vm._s(_vm.getStudyUnitOrder( question.evaluation_criteria_micro_obj.study_unit ) || "")+_vm._s(question.evaluation_criteria_micro_obj.order)+" ")]:[_vm._v("N/A")]],2),_c('b-td',[(question.evaluation_criteria_micro_obj != null)?[_c('SentenceContainer',{attrs:{"unique_id":'question:' +
                      question.id +
                      'question_type' +
                      question.question_type +
                      'test:' +
                      _vm.test.id,"Sentence":question.evaluation_criteria_micro_obj}})]:[_vm._v("N/A")]],2)],1)}),(_vm.questionList.length == 0)?_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":6}},[_vm._v(" No hay datos que mostrar ")])],1):_vm._e()],2)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_c('b-icon',{attrs:{"icon":"list-check"}}),_vm._v(" POR CRITERIO ")],1)]},proxy:true}],null,false,4254710544)},[_c('b-table-simple',{attrs:{"bordered":"","small":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class",attrs:{"colspan":2}},[_vm._v(_vm._s(_vm.$getVisibleNames( "manual.indicador_de_logro", true, "Indicadores de Logros" ).toUpperCase()))]),_c('b-th',{staticClass:"th-class",attrs:{"colspan":3}},[_c('span',[_vm._v(" PREGUNTAS ")]),(
                    _vm.allows_crud &&
                    _vm.test &&
                    !_vm.test.question_creation_is_locked &&
                    (!_vm.evaluationSituation ||
                      (_vm.evaluationSituation &&
                        ![
                          'duoc_ejecucion_practica_test',
                          'duoc_entrega_de_encargo_test',
                          'duoc_presentacion_test',
                        ].includes(_vm.evaluationSituation.internal_use_id) &&
                        _vm.evaluationSituation.enable_questions &&
                        (_vm.evaluationSituation.enable_redaction_questions ||
                          _vm.evaluationSituation.enable_simple_selection_questions ||
                          _vm.evaluationSituation.enable_multiple_selection_questions ||
                          _vm.evaluationSituation.enable_matching_questions)))
                  )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                    `Agregar Pregunta`
                  ),expression:"\n                    `Agregar Pregunta`\n                  ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"m-0 mb-1 p-0 text-white",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-created-question-nw-st-${_vm.test.id}`)}}}):_vm._e()],1)],1),_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v("N°")]),_c('b-th',{staticClass:"th-class"},[_vm._v("ENUNCIADO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("CANTIDAD")]),_c('b-th',{staticClass:"th-class"},[_vm._v("TIPO DE PREGUNTA")]),_c('b-th',{staticClass:"th-class"},[_vm._v("N° DE ORDEN")])],1)],1),_c('b-tbody',[_vm._l((_vm.evaluation_criteria_micros.filter(
                (x) => _vm.evaluation_criteria_micro_ids.includes(x.id)
              )),function(evaluation_criteria){return [(
                  _vm.questionList.filter(
                    (x) =>
                      x.evaluation_criteria_micro == evaluation_criteria.id
                  ).length == 0
                )?_c('b-tr',{key:evaluation_criteria.id + '-' + _vm.test.id},[_c('b-td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.getStudyUnitOrder(evaluation_criteria.study_unit) || "")+_vm._s(evaluation_criteria.order)+" ")]),_c('b-td',[_c('SentenceContainer',{attrs:{"unique_id":'evaluation_criteria:' +
                      evaluation_criteria.id +
                      'test:' +
                      _vm.test.id,"Sentence":evaluation_criteria}})],1),_c('b-td',{staticClass:"text-center"},[_vm._v("0")]),_c('b-td',{staticClass:"text-center"},[_vm._v("N/A")]),_c('b-td',{staticClass:"text-center"},[_vm._v("N/A")])],1):_vm._e(),_vm._l((_vm.questionList.filter(
                  (x) => x.evaluation_criteria_micro == evaluation_criteria.id
                )),function(question,index){return _c('b-tr',{key:index +
                  '-' +
                  evaluation_criteria.id +
                  '-' +
                  question.id +
                  '-' +
                  question.question_type +
                  '-' +
                  _vm.test.id},[(index == 0)?_c('b-td',{staticClass:"text-center align-middle",attrs:{"rowspan":_vm.questionList.filter(
                      (x) =>
                        x.evaluation_criteria_micro == evaluation_criteria.id
                    ).length}},[_vm._v(" "+_vm._s(_vm.getStudyUnitOrder(evaluation_criteria.study_unit) || "")+_vm._s(evaluation_criteria.order)+" ")]):_vm._e(),(index == 0)?_c('b-td',{staticClass:"align-middle",attrs:{"rowspan":_vm.questionList.filter(
                      (x) =>
                        x.evaluation_criteria_micro == evaluation_criteria.id
                    ).length}},[_c('SentenceContainer',{attrs:{"unique_id":'evaluation_criteria:' +
                      evaluation_criteria.id +
                      'test:' +
                      _vm.test.id,"Sentence":evaluation_criteria}})],1):_vm._e(),(index == 0)?_c('b-td',{staticClass:"text-center align-middle",attrs:{"rowspan":_vm.questionList.filter(
                      (x) =>
                        x.evaluation_criteria_micro == evaluation_criteria.id
                    ).length}},[_vm._v(" "+_vm._s(_vm.questionList.filter( (x) => x.evaluation_criteria_micro == evaluation_criteria.id ).length))]):_vm._e(),_c('b-td',{staticClass:"pl-3"},[_vm._v(_vm._s(_vm.getQuestionType(question.question_type)))]),_c('b-td',{staticClass:"text-right"},[(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                      `Editar Pregunta`
                    ),expression:"\n                      `Editar Pregunta`\n                    ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                        `modal-updated-question-nw-st-2-${question.test}-${question.question_type}-${question.id}`
                      )}}}):_vm._e(),_c('b-modal',{attrs:{"id":`modal-updated-question-nw-st-2-${question.test}-${question.question_type}-${question.id}`,"title":`Editar ${_vm.$getVisibleNames(
                      'evaluations2.redactionquestion',
                      false,
                      'Pregunta'
                    )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                          `modal-updated-question-nw-st-2-${question.test}-${question.question_type}-${question.id}`
                        )}}})],1)],1)],1)})]})],2)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_c('b-icon',{attrs:{"icon":"list-ol"}}),_vm._v(" POR NIVEL COGNITIVO ")],1)]},proxy:true}],null,false,3910058424)},[_c('NewCognitiveLevelST',{attrs:{"allows_crud":_vm.allows_crud,"test":_vm.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids}})],1)],1)]:(_vm.rubric_id != null)?[_c('b-tabs',{scopedSlots:_vm._u([{key:"tabs-end",fn:function(){return [_c('InfoModal',{staticClass:"ml-1",attrs:{"tooltip_text":`Presione para poder visualizar los colores de los ${_vm.$getVisibleNames(
            'mesh.cognitivelevel',
            true,
            'Niveles Cognitivos'
          )}.`,"modal_title":`Información de los ${_vm.$getVisibleNames(
            'mesh.cognitivelevel',
            true,
            'Niveles Cognitivos'
          )}.`}},[_c('CognitiveColorTable')],1)]},proxy:true}]),model:{value:(_vm.tab_index2),callback:function ($$v) {_vm.tab_index2=$$v},expression:"tab_index2"}},[_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_c('b-icon',{attrs:{"icon":"list-ol"}}),_vm._v(" POR NIVEL COGNITIVO ")],1)]},proxy:true}])},[_c('NewCognitiveLevelDimensionsST',{attrs:{"allows_crud":_vm.allows_crud,"test":_vm.test,"rubric_id":_vm.rubric_id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids}})],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_c('b-icon',{attrs:{"icon":"list-check"}}),_vm._v(" POR CRITERIO ")],1)]},proxy:true}])},[_c('NewEvaluationCriterialDimensionsST',{attrs:{"allows_crud":_vm.allows_crud,"test":_vm.test,"rubric_id":_vm.rubric_id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids}})],1)],1)]:_vm._e(),(_vm.test)?_c('b-modal',{attrs:{"id":`modal-created-question-nw-st-${_vm.test.id}`,"title":`Crear Pregunta`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":_vm.test.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.filterQuestionBySegment().length > 0
          ? _vm.filterQuestionBySegment()[_vm.filterQuestionBySegment().length - 1]
              .order + 1
          : 1,"evaluation_situations":_vm.evaluation_situations},on:{"closeModal":function($event){return _vm.$bvModal.hide(`modal-created-question-nw-st-${_vm.test.id}`)}}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }