<template>
  <div>
    <template v-if="!observation_table">
      <b-tabs v-model="tab_index">
        <!-- ############################## Por Pregunta ############################## -->
        <b-tab>
          <template #title>
            <span>
              <b-icon icon="card-text"></b-icon>
              POR PREGUNTA
            </span>
          </template>
          <b-table-simple bordered small>
            <b-thead>
              <b-tr>
                <b-th class="th-class" :colspan="4">
                  <span> PREGUNTAS </span>
                  <button-add
                    v-if="
                      allows_crud &&
                      test &&
                      !test.question_creation_is_locked &&
                      (!evaluationSituation ||
                        (evaluationSituation &&
                          ![
                            'duoc_ejecucion_practica_test',
                            'duoc_entrega_de_encargo_test',
                            'duoc_presentacion_test',
                          ].includes(evaluationSituation.internal_use_id) &&
                          evaluationSituation.enable_questions &&
                          (evaluationSituation.enable_redaction_questions ||
                            evaluationSituation.enable_simple_selection_questions ||
                            evaluationSituation.enable_multiple_selection_questions ||
                            evaluationSituation.enable_matching_questions)))
                    "
                    class="m-0 mb-1 p-0 text-white"
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Agregar Pregunta`
                    "
                    @click="
                      $bvModal.show(`modal-created-question-nw-st-${test.id}`)
                    "
                    size="sm"
                  >
                  </button-add>
                </b-th>
                <b-th class="th-class" :colspan="2">{{
                  $getVisibleNames(
                    "manual.indicador_de_logro",
                    true,
                    "Indicadores de Logros"
                  ).toUpperCase()
                }}</b-th>
              </b-tr>
              <b-tr>
                <b-th class="th-class">N° ORDEN</b-th>
                <b-th class="th-class">TIPO DE PREGUNTA</b-th>
                <b-th class="th-class">SUB-TIPO DE PREGUNTA</b-th>
                <b-th class="th-class">PUNTAJE</b-th>
                <b-th class="th-class">N°</b-th>
                <b-th class="th-class">ENUNCIADO</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="question in questionList"
                :key="
                  question.id + '-' + question.question_type + '-' + test.id
                "
              >
                <b-td class="text-right">
                  <span v-if="question.segment_order != null">
                    {{ question.segment_order }}.
                  </span>
                  {{ question.order }}
                  <button-edit
                    v-if="allows_crud && !question.is_locked"
                    class="m-0 p-0"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar Pregunta`
                    "
                    @click="
                      $bvModal.show(
                        `modal-updated-question-nw-st-1-${question.test}-${question.question_type}-${question.id}`
                      )
                    "
                  ></button-edit>
                  <b-modal
                    :id="`modal-updated-question-nw-st-1-${question.test}-${question.question_type}-${question.id}`"
                    :title="`Editar ${$getVisibleNames(
                      'evaluations2.redactionquestion',
                      false,
                      'Pregunta'
                    )}`"
                    hide-footer
                    size="lg"
                  >
                    <NewQuestionForm
                      :test_id="question.test"
                      :evaluation_criteria_micro_ids="
                        evaluation_criteria_micro_ids
                      "
                      :Question="question"
                      :evaluation_situations="evaluation_situations"
                      :show_delete_button="true"
                      @closeModal="
                        $bvModal.hide(
                          `modal-updated-question-nw-st-1-${question.test}-${question.question_type}-${question.id}`
                        )
                      "
                    ></NewQuestionForm>
                  </b-modal>
                </b-td>
                <b-td>{{ getQuestionType(question.question_type) }}</b-td>
                <b-td>{{ getQuestionSubType(question) }}</b-td>
                <b-td class="text-center"> {{ question.max_score }}</b-td>
                <b-td class="text-center">
                  <template
                    v-if="question.evaluation_criteria_micro_obj != null"
                  >
                    {{
                      getStudyUnitOrder(
                        question.evaluation_criteria_micro_obj.study_unit
                      ) || ""
                    }}{{ question.evaluation_criteria_micro_obj.order }}
                  </template>
                  <template v-else>N/A</template>
                </b-td>
                <b-td>
                  <template
                    v-if="question.evaluation_criteria_micro_obj != null"
                  >
                    <SentenceContainer
                      :unique_id="
                        'question:' +
                        question.id +
                        'question_type' +
                        question.question_type +
                        'test:' +
                        test.id
                      "
                      :Sentence="question.evaluation_criteria_micro_obj"
                    ></SentenceContainer>
                  </template>
                  <template v-else>N/A</template>
                </b-td>
              </b-tr>
              <b-tr v-if="questionList.length == 0">
                <b-td class="text-center" :colspan="6">
                  No hay datos que mostrar
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <!-- ############################## Por Criterio ############################## -->
        <b-tab>
          <template #title>
            <span> <b-icon icon="list-check"></b-icon> POR CRITERIO </span>
          </template>
          <b-table-simple bordered small>
            <b-thead>
              <b-tr>
                <b-th class="th-class" :colspan="2">{{
                  $getVisibleNames(
                    "manual.indicador_de_logro",
                    true,
                    "Indicadores de Logros"
                  ).toUpperCase()
                }}</b-th>
                <b-th class="th-class" :colspan="3">
                  <span> PREGUNTAS </span>
                  <button-add
                    v-if="
                      allows_crud &&
                      test &&
                      !test.question_creation_is_locked &&
                      (!evaluationSituation ||
                        (evaluationSituation &&
                          ![
                            'duoc_ejecucion_practica_test',
                            'duoc_entrega_de_encargo_test',
                            'duoc_presentacion_test',
                          ].includes(evaluationSituation.internal_use_id) &&
                          evaluationSituation.enable_questions &&
                          (evaluationSituation.enable_redaction_questions ||
                            evaluationSituation.enable_simple_selection_questions ||
                            evaluationSituation.enable_multiple_selection_questions ||
                            evaluationSituation.enable_matching_questions)))
                    "
                    class="m-0 mb-1 p-0 text-white"
                    v-b-tooltip.v-secondary.top.noninteractive="
                      `Agregar Pregunta`
                    "
                    @click="
                      $bvModal.show(`modal-created-question-nw-st-${test.id}`)
                    "
                    size="sm"
                  >
                  </button-add>
                </b-th>
              </b-tr>
              <b-tr>
                <b-th class="th-class">N°</b-th>
                <b-th class="th-class">ENUNCIADO</b-th>
                <b-th class="th-class">CANTIDAD</b-th>
                <b-th class="th-class">TIPO DE PREGUNTA</b-th>
                <b-th class="th-class">N° DE ORDEN</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template
                v-for="evaluation_criteria in evaluation_criteria_micros.filter(
                  (x) => evaluation_criteria_micro_ids.includes(x.id)
                )"
              >
                <b-tr
                  v-if="
                    questionList.filter(
                      (x) =>
                        x.evaluation_criteria_micro == evaluation_criteria.id
                    ).length == 0
                  "
                  :key="evaluation_criteria.id + '-' + test.id"
                >
                  <b-td class="text-center">
                    {{ getStudyUnitOrder(evaluation_criteria.study_unit) || ""
                    }}{{ evaluation_criteria.order }}
                  </b-td>
                  <b-td>
                    <SentenceContainer
                      :unique_id="
                        'evaluation_criteria:' +
                        evaluation_criteria.id +
                        'test:' +
                        test.id
                      "
                      :Sentence="evaluation_criteria"
                    ></SentenceContainer>
                  </b-td>
                  <b-td class="text-center">0</b-td>
                  <b-td class="text-center">N/A</b-td>
                  <b-td class="text-center">N/A</b-td>
                </b-tr>
                <b-tr
                  v-for="(question, index) in questionList.filter(
                    (x) => x.evaluation_criteria_micro == evaluation_criteria.id
                  )"
                  :key="
                    index +
                    '-' +
                    evaluation_criteria.id +
                    '-' +
                    question.id +
                    '-' +
                    question.question_type +
                    '-' +
                    test.id
                  "
                >
                  <b-td
                    v-if="index == 0"
                    :rowspan="
                      questionList.filter(
                        (x) =>
                          x.evaluation_criteria_micro == evaluation_criteria.id
                      ).length
                    "
                    class="text-center align-middle"
                  >
                    {{ getStudyUnitOrder(evaluation_criteria.study_unit) || ""
                    }}{{ evaluation_criteria.order }}
                  </b-td>
                  <b-td
                    v-if="index == 0"
                    :rowspan="
                      questionList.filter(
                        (x) =>
                          x.evaluation_criteria_micro == evaluation_criteria.id
                      ).length
                    "
                    class="align-middle"
                  >
                    <SentenceContainer
                      :unique_id="
                        'evaluation_criteria:' +
                        evaluation_criteria.id +
                        'test:' +
                        test.id
                      "
                      :Sentence="evaluation_criteria"
                    ></SentenceContainer>
                  </b-td>
                  <b-td
                    v-if="index == 0"
                    :rowspan="
                      questionList.filter(
                        (x) =>
                          x.evaluation_criteria_micro == evaluation_criteria.id
                      ).length
                    "
                    class="text-center align-middle"
                  >
                    {{
                      questionList.filter(
                        (x) =>
                          x.evaluation_criteria_micro == evaluation_criteria.id
                      ).length
                    }}</b-td
                  >
                  <b-td class="pl-3">{{
                    getQuestionType(question.question_type)
                  }}</b-td>
                  <b-td class="text-right">
                    <span v-if="question.segment_order != null">
                      {{ question.segment_order }}.
                    </span>
                    {{ question.order }}
                    <button-edit
                      v-if="allows_crud && !question.is_locked"
                      class="m-0 p-0"
                      v-b-tooltip.top.noninteractive.v-secondary="
                        `Editar Pregunta`
                      "
                      @click="
                        $bvModal.show(
                          `modal-updated-question-nw-st-2-${question.test}-${question.question_type}-${question.id}`
                        )
                      "
                    ></button-edit>
                    <b-modal
                      :id="`modal-updated-question-nw-st-2-${question.test}-${question.question_type}-${question.id}`"
                      :title="`Editar ${$getVisibleNames(
                        'evaluations2.redactionquestion',
                        false,
                        'Pregunta'
                      )}`"
                      hide-footer
                      size="lg"
                    >
                      <NewQuestionForm
                        :test_id="question.test"
                        :evaluation_criteria_micro_ids="
                          evaluation_criteria_micro_ids
                        "
                        :Question="question"
                        :evaluation_situations="evaluation_situations"
                        :show_delete_button="true"
                        @closeModal="
                          $bvModal.hide(
                            `modal-updated-question-nw-st-2-${question.test}-${question.question_type}-${question.id}`
                          )
                        "
                      ></NewQuestionForm>
                    </b-modal>
                  </b-td>
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </b-tab>
        <!-- ############################## Por Nivel Cognitivo ############################## -->
        <b-tab>
          <template #title>
            <span> <b-icon icon="list-ol"></b-icon> POR NIVEL COGNITIVO </span>
          </template>
          <NewCognitiveLevelST
            :allows_crud="allows_crud"
            :test="test"
            :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
          ></NewCognitiveLevelST>
        </b-tab>
      </b-tabs>
    </template>
    <template v-else-if="rubric_id != null">
      <b-tabs v-model="tab_index2">
        <template #tabs-end>
          <InfoModal
            class="ml-1"
            :tooltip_text="`Presione para poder visualizar los colores de los ${$getVisibleNames(
              'mesh.cognitivelevel',
              true,
              'Niveles Cognitivos'
            )}.`"
            :modal_title="`Información de los ${$getVisibleNames(
              'mesh.cognitivelevel',
              true,
              'Niveles Cognitivos'
            )}.`"
          >
            <CognitiveColorTable></CognitiveColorTable>
          </InfoModal>
        </template>
        <!-- ##############################  Por Nivel Cognitivo ############################## -->
        <b-tab>
          <template #title>
            <span>
              <b-icon icon="list-ol"></b-icon>
              POR NIVEL COGNITIVO
            </span>
          </template>
          <NewCognitiveLevelDimensionsST
            :allows_crud="allows_crud"
            :test="test"
            :rubric_id="rubric_id"
            :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
          ></NewCognitiveLevelDimensionsST>
        </b-tab>
        <!-- ##############################  Por Criterio ############################## -->
        <b-tab>
          <template #title>
            <span> <b-icon icon="list-check"></b-icon> POR CRITERIO </span>
          </template>
          <NewEvaluationCriterialDimensionsST
            :allows_crud="allows_crud"
            :test="test"
            :rubric_id="rubric_id"
            :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
          ></NewEvaluationCriterialDimensionsST>
        </b-tab>
      </b-tabs>
    </template>
    <b-modal
      v-if="test"
      :id="`modal-created-question-nw-st-${test.id}`"
      :title="`Crear Pregunta`"
      hide-footer
      size="lg"
    >
      <NewQuestionForm
        :test_id="test.id"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :order="
          filterQuestionBySegment().length > 0
            ? filterQuestionBySegment()[filterQuestionBySegment().length - 1]
                .order + 1
            : 1
        "
        :evaluation_situations="evaluation_situations"
        @closeModal="$bvModal.hide(`modal-created-question-nw-st-${test.id}`)"
      ></NewQuestionForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewSpecificationTable",
  components: {
    NewCognitiveLevelDimensionsST: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/NewCognitiveLevelDimensionsST"
      ),
    NewCognitiveLevelST: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/NewCognitiveLevelST"
      ),
    NewEvaluationCriterialDimensionsST: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/NewEvaluationCriterialDimensionsST"
      ),
    NewQuestionForm: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/NewQuestionForm"
      ),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    CognitiveColorTable: () =>
      import("@/components/reusable/CognitiveColorTable"),
    InfoModal: () => import("@/components/reusable/InfoModal"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Object,
      required: true,
    },
    observation_table: {
      type: Boolean,
      default: false,
    },
    rubric_id: {
      type: [Number, null],
      default: null,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    evaluation_situations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      tab_index: 0,
      tab_index2: 0,
      question_types: [
        { id: 1, value: "Desarrollo" },
        { id: 2, value: "Selección" },
        // { id: 3, value: "Auto Completar" },
        { id: 4, value: "Emparejamiento" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubricCriterias: names.NEW_RUBRIC_CRITERIAS,
      test_segments: names.NEW_TEST_SEGMENTS,
      studyUnits: names.STUDY_UNITS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      // filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
    }),
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    rubric() {
      return this.rubrics.find((x) => x.test == this.test.id);
    },
    rubricCriteriaList() {
      if (!this.rubric) return [];
      return this.rubricCriterias
        .filter((x) => x.rubric == this.rubric.id)
        .map((x) => ({
          ...x,
          evaluation_criteria_micro:
            this.evaluation_criteria_micros.find(
              (s) => s.id == x.evaluation_criteria_micro
            ) || null,
        }))
        .sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
    },
    redactionQuestions() {
      return this.redaction_questions.filter((x) => x.test == this.test.id);
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.test.id
      );
    },
    // fillingQuestions() {
    //   return this.filling_questions.filter((x) => x.test == this.test.id);
    // },
    matchingQuestions() {
      return this.matching_questions.filter((x) => x.test == this.test.id);
    },
    questionList() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //     segment_order:
      //       (this.test_segments.find((s) => s.id == question.segment) || {})
      //         .order || null,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      if (this.institution && this.institution.internal_use_id == "duoc_uc") {
        list = list.map((question) => {
          const evaluation_criteria_micro =
            (
              this.rubricCriteriaList.find(
                (s) => s.id === question.linked_rubric_criteria
              ) || {}
            ).evaluation_criteria_micro || null;
          return {
            ...question,
            evaluation_criteria_micro_obj: evaluation_criteria_micro,
            evaluation_criteria_micro: evaluation_criteria_micro
              ? evaluation_criteria_micro.id
              : null,
          };
        });
      } else {
        list = list.map((question) => ({
          ...question,
          evaluation_criteria_micro_obj:
            this.evaluation_criteria_micros.find(
              (s) =>
                s.id == question.evaluation_criteria_micro &&
                this.evaluation_criteria_micro_ids.includes(s.id)
            ) || null,
        }));
      }
      return list.sort(function (a, b) {
        if (a.segment_order < b.segment_order) return 1;
        if (a.segment_order > b.segment_order) return -1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
  },
  methods: {
    filterQuestionBySegment(segment_id = null) {
      return this.questionList.filter((x) => x.segment == segment_id);
    },
    getStudyUnitOrder(study_unit_id) {
      const study_unit = this.studyUnits.find((x) => x.id == study_unit_id);
      return study_unit ? study_unit.order + "." : null;
    },
    getQuestionType(question_type_value) {
      const question_type = this.question_types.find(
        (x) => x.id == question_type_value
      );
      return question_type ? question_type.value : "N/A";
    },
    getQuestionSubType(question) {
      if ([3, 4].includes(question.question_type)) return;
      else if (question.question_type == 2) {
        if (question.subtype == 2) return "Múltiple";
        else if (question.subtype == 1) return "Simple";
      } else if (question.question_type == 1) {
        if (question.max_answer_length_type == null) return "Extenso";
        else return "Breve";
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.th-class {
  background-color: var(--kl-menu-color) !important;
  color: var(--secondary-font-color);
  font-size: var(--secondary-font-size);
  text-align: center;
}
.underline-cell {
  text-decoration: underline;
  cursor: help;
}
</style>

